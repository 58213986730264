

import React, { useState, useEffect, useRef } from "react";
import { useNavigate } from "react-router-dom";
import styled from "styled-components";
import "./SideBar.css";
import { getData } from "./api/Look.js";

import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import Select, { SelectChangeEvent } from '@mui/material/Select';



export default function SideBar({ updateSelectedYear }) {
  const [data, setData] = useState([]);
  const [selectedYear, setSelectedYear] = useState("2023"); // Set a default value
  const clickCount = useRef(0);
  const navigate = useNavigate();
  let hdfc_rev=0,icici_rev=0, bajaj_rev=0;
  useEffect(() => {
    const fetchData = async () => {
      try {
        const result = await getData();
        setData(result.success);
      } catch (error) {
        console.error("Error fetching data:", error);
      }
    };

    fetchData();
  }, []);

  const selectYearHandler = (event) => {
    const newYear = event.target.value;
    setSelectedYear(event.target.value);
    updateSelectedYear(newYear);
  };
  localStorage.setItem("selectedyear", selectedYear);
  // console.log("side bar local strg year",localStorage.getItem("selectedyear"))
  function formatNumber(num) {
    return (+num).toLocaleString("en-US");
  }
  const handleButtonCLick = () => {
    clickCount.current += 1;
    console.log("Click count");

    if (clickCount.current === 2) {
      navigate("./Sales");
      console.log("If double clicked");
    }

    setTimeout(() => {
      clickCount.current = 0;
    }, 300);
  };

  const HeaderLogoComponent = styled.div`
    display: flex;
    align-items: center;
    img {
      width: 250px;
      height: 60px;
      border-radius: ${(props) => (props.index === 2 ? 15 : 10)}px;
      border: 1px solid #d5d5d5;
    }
    align-items: center;
    border-radius: ${(props) => (props.index === 2 ? 20 : 20)}px;
    margin-bottom: ${(props) => (props.index === 3 ? 20 : 20)}px;
    background-color: white;
    box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2);
    transition: 0.3s;
    height: 50px;
    width: 150px;
    margin-right: 30px;
  `;

  return (
    <>
      {/* <div className="main__top"> */}
        {/* <div className="containerr1"> */}
          {/* <select
            name="year"
            id="years"
            onChange={selectYearHandler}
            value={selectedYear}
          >
            <option value="2023">2023</option>
            <option value="2022">2022</option>
            <option value="2021">2021</option>
          </select> */}
<div className="year_drop">
     <FormControl sx={{ m: 1, minWidth: 120 }} size="small">
      <InputLabel id="demo-select-small-label">Year</InputLabel>
      <Select
      name="year"
        labelId="demo-select-small-label"
        id="demo-select-small"
        value={selectedYear}
        label="Year"
        onChange={selectYearHandler}
      >
        
        <MenuItem value={2023}>2023</MenuItem>
        <MenuItem value={2022}>2022</MenuItem>
        <MenuItem value={2021}>2021</MenuItem>
      </Select>
    </FormControl>
    </div>
        {/* </div> */}
      {/* </div> */}

      <div className="sidebar">
        <div className="competitors-content">
          <div className="nextword">
            <p>Premium Comparison</p>
          </div>
        </div>
        {
          data.filter((item)=>item["data_iter_2.date_year"] === parseInt(selectedYear)).map(
            (item)=>{
              switch(item["data_iter_2.insurance_company"]){
                case "Bajaj Allianz":
                  bajaj_rev=item["data_iter_2.total_revenue"]/10000000;
                  break
                case "ICICI Lombard":
                  icici_rev=item["data_iter_2.total_revenue"]/10000000;
                  break
                case "HDFC Ergo":
                  hdfc_rev=item["data_iter_2.total_revenue"]/10000000;
                  break
              }
            }
          )
        }
        {data
          .filter(
            (item) =>
              item["data_iter_2.date_year"] === parseInt(selectedYear)
          )
          .map((item, index) => {
            let bgColor;
            if (index === 2) {
              bgColor = "#004da8"; // bajaj
            } else if (index === 1) {
              bgColor = "#EF8435"; // icici
            } else {
              bgColor = "#D00404"; // Mahindra
            }

            return (
              <div
                onClick={handleButtonCLick}
                className={`sidebar__top container${index + 1}`}
                key={index}
                style={{ position: "relative" }}
              >
                <span
                  style={{
                    width: "5px",
                    height: "50px",
                    backgroundColor: bgColor,
                    display: "flex",
                    position: "absolute",
                    left: 0,
                    marginTop: "35px",
                  }}
                ></span>
                <HeaderLogoComponent index={index + 1}>
                  <img
                    src={
                      index === 2
                        ? "/Bajaj_new.png"
                        : index === 1
                        ? "/icici_new1.png"
                        : "/HDFC_new1.jpg"
                    }
                    alt={`logo ${index + 1}`}
                  />
                </HeaderLogoComponent>
                <div
                  style={{
                    display: "flex",
                    flexDirection: "column",
                    justifyContent: "center",
                    marginBottom: "40px",
                    marginTop: "10px",
                  }}
                  className="brand"
                >
                  <h2  style={{ whiteSpace: "nowrap",fontSize:"large" }}>
                    {index === 2
                      ? "Bajaj Allianz"
                      : index === 1
                      ? "ICICI Lombard"
                      : "HDFC Ergo"}
                  </h2>

                  <h4 style={{ marginTop: "7px" }}>
                    <b style={{ whiteSpace: "nowrap" }}>
                    ₹ {
                      index===2 ? (bajaj_rev).toFixed(2) : index===1 ? (icici_rev).toFixed(2) : (hdfc_rev).toFixed(2)
                    } Cr
                    </b>
                  </h4>
                </div>
              </div>
            );
          })}
      </div>
    </>
  );
}
