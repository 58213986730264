
import React, { useEffect, useState } from "react";
import {
  AreaChart,
  Area,
  CartesianGrid,
  ResponsiveContainer,
  defs,
  linearGradient,
  stop,
  YAxis,
  Tooltip,
} from "recharts";
import ArrowCircleDownIcon from "@mui/icons-material/ArrowCircleDown";
import CheckCircleIcon from "@mui/icons-material/CheckCircle";
import "./style.css";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faArrowCircleDown } from "@fortawesome/free-solid-svg-icons";
import { faCircleCheck } from "@fortawesome/free-solid-svg-icons";
import {
 funnelData,
 funnelEntityData,
 entityPercentDifference,
} from "../api/Look.js";
import {TooltipBar_Enq, TooltipBar_testDrives, TooltipBar_bk, TooltipBar_ts} from "./TooltipBar";


const Graph = ({ sel_year }) => {
 let Enquiries,
   First_Cut,
    Negotiated_Quote,
   Total_Policies,
   Enquiries_b="NA",Total_Policies_b="NA",First_Cut_b="NA",Negotiated_Quote_b="NA"
 //let enq_a, enq_b, td_a, td_b, bk_a, bk_b, ts_a, ts_b;
 // let enq_diff, td_diff, bk_diff, ts_diff;
 let enq_a,enq_b, td_a, td_b, bk_a, bk_b, ts_a, ts_b;
 let drop_off1=0,drop_off2=0,drop_off3=0,conversion=0;
 const [funneldata, setFunnelData] = useState([]);
 const [funnelEntitydata, setFunnelEntityData] = useState([]);
 const [funnelEntityPercentdata, setFunnelEntityPercentData] = useState([]);
  // const [isHovered, setIsHovered] = useState(false);


 useEffect(() => {
   const fetchData = async () => {
     try {
       const result = await funnelData();
       setFunnelData(result.success);
     } catch (error) {
       console.error("Error fetching data:", error);
     }
   };


   fetchData();
 }, []);


 useEffect(() => {
   const fetchData = async () => {
     try {
       const result = await entityPercentDifference();
       setFunnelEntityPercentData(result.success);
     } catch (error) {
       console.error("Error fetching data:", error);
     }
   };


   fetchData();
 }, []);




 funneldata
   .filter((item) => item["data_iter_2.date_year"] === parseInt(sel_year))
   .map((item, index) => {
     Enquiries = item["data_iter_2.total_enquiries"];
     First_Cut = item["data_iter_2.total_firstCut"];
     Negotiated_Quote = item["data_iter_2.total_negotiatedQuote"];
     Total_Policies = item["data_iter_2.total_policiesIssued"];

     drop_off1=((item["data_iter_2.total_enquiries"]-item["data_iter_2.total_firstCut"])/item["data_iter_2.total_enquiries"]*100).toFixed(2);
     drop_off2=((item["data_iter_2.total_firstCut"]-item["data_iter_2.total_negotiatedQuote"])/item["data_iter_2.total_firstCut"]*100).toFixed(2);
     drop_off3=((item["data_iter_2.total_negotiatedQuote"]-item["data_iter_2.total_policiesIssued"])/item["data_iter_2.total_negotiatedQuote"]*100).toFixed(2);
     conversion=((item["data_iter_2.total_enquiries"]-item["data_iter_2.total_policiesIssued"])/item["data_iter_2.total_enquiries"]*100).toFixed(2);
   });

   if(sel_year!=2021){
     funneldata.filter((item)=>item["data_iter_2.date_year"] === parseInt(sel_year)-1).map((item,index)=>{
       Enquiries_b = item["data_iter_2.total_enquiries"];
      First_Cut_b = item["data_iter_2.total_firstCut"];
      Negotiated_Quote_b = item["data_iter_2.total_negotiatedQuote"];
      Total_Policies_b = item["data_iter_2.total_policiesIssued"];
  })
    
   }


 const data = [
   { name: "Segment 1", value: Enquiries },
   { name: "Segment 2", value: First_Cut },
 ];


 const data1 = [
   { name: "Segment 1", value: First_Cut },
   { name: "Segment 2", value: Negotiated_Quote },
 ];
 const data2 = [
   { name: "Segment 1", value: Negotiated_Quote },
   { name: "Segment 2", value: Total_Policies },
 ];
 const data3 = [
   { name: "Segment 1", value: Total_Policies },
   { name: "Segment 2", value: Total_Policies },
 ];
 const graphContainerStyle = {
   position: "relative",
   textAlign: "center",
   paddingTop: "50px",
   // paddingBottom: '0px',
   left: "10px",
 };


 const graphContainerStyle1 = {
   position: "relative",
   textAlign: "center",
   paddingTop: "50px",
   // paddingBottom: '50px',
 };
 const graphContainerStyle2 = {
   position: "relative",
   textAlign: "center",
   paddingTop: "50px",
   // paddingBottom: '50px',
   right: "10px",
 };
 const graphContainerStyle3 = {
   position: "relative",
   textAlign: "center",
   paddingTop: "50px",
   // paddingBottom: '50px',
   right: "20px",
 };
 const graphLineStyle = {
   content: '""',
   position: "absolute",
   top: 0,
   bottom: "20px",
   right: "2px", // Adjust this value as needed to align the line with the graph
   borderRight: "2px solid #8e918f", // Change the style of the connecting line here
 };


 // const [isHovered, setIsHovered] = useState(null);
 const [isHovered1, setIsHovered1] = useState(false);
 const [isHovered2, setIsHovered2] = useState(false);
 const [isHovered3, setIsHovered3] = useState(false);
 const [isHovered4, setIsHovered4] = useState(false);

 const graphstyle = {
  display: "flex",
  marginLeft: "90px",
};
function formatNumber(num) {
  return (+num).toLocaleString("en-US");
}


 // const handleMouseEnter = () => {
 //   setIsHovered(true);
 // };


 // const handleMouseLeave = () => {
 //   setIsHovered(false);
 // };
 const handleMouseEnter1 = () => {
   console.log("true");
   setIsHovered1(true);
   console.log(isHovered1);
 };


 const handleMouseLeave1 = () => {
   setIsHovered1(false);
 };


 const handleMouseEnter2 = () => {
   setIsHovered2(true);
 };


 const handleMouseLeave2 = () => {
   setIsHovered2(false);
 };


 const handleMouseEnter3 = () => {
   setIsHovered3(true);
 };


 const handleMouseLeave3 = () => {
   setIsHovered3(false);
 };


 const handleMouseEnter4 = () => {
   setIsHovered4(true);
 };


 const handleMouseLeave4 = () => {
   setIsHovered4(false);
 };


 // const headerData=[
 //   {name1:"Enquiries"},
 //   {name2:"Test Drives"},
 //   {name3:"Bookings"},
 //   {name4:"Purchases"}
 // ]


 return (
  
<div
 style={{
   display: "flex",
   backgroundColor: "white",
   boxShadow:"5px 5px 10px 0px rgba(0, 0, 0, 0.2)",
   justifyContent: "flex-start", // or "center"
   alignItems: "center",
   border: "2px solid #DFE6EA",
   borderRadius: "20px",
   paddingTop: "20px",
   marginTop: "40px",
   marginLeft: "30px",
   height: "650px",
   width: "1600px",
 }}
>
 <div style={graphstyle}>
   <div style={graphContainerStyle}>
     <div
       style={{
         color: "#8e918f",
         fontSize: 16,
         display: "flex",
         paddingLeft: " 20px",
       }}
     >
       Step 1
     </div>
     <div
       style={{
         display: "flex",
         paddingLeft: " 20px",
         paddingTop: "20px",
         fontSize: "22px",
         fontFamily:"cursive"
       }}
     >
       Enquiries
     </div>
     <div
       style={{
         display: "flex",
         justifyContent: "space-between",
         padding: " 20px",
         paddingTop: "5px",
       }}
     >
       <span style={{ fontWeight: "bold", fontSize: "25px" }}>
         {formatNumber(Enquiries)}
       </span>
       <span
         style={{ color: "#8e918f", fontSize: "18px",fontWeight:"bold", marginTop: "5px" }}
       >
         {formatNumber(Enquiries_b)} last year
       </span>
     </div>
     <ResponsiveContainer width={350} height={350}>
       <AreaChart data={data}>
       <Tooltip active={isHovered1} content={<TooltipBar_Enq sel_year={sel_year}/>} />

         <defs>
           <linearGradient id="gradient1" x1="0" y1="0" x2="0" y2="1">
             <stop offset="5%" stopColor="red" />
             <stop offset="95%" stopColor="maroon" />
           </linearGradient>
         </defs>
         <YAxis
           domain={[300000, 1500000]}
           ticks={[
             300000, 600000, 900000, 1200000, 1500000,
           ]}
           hide={true}
           tickLine={false}
         />
         <CartesianGrid strokeDasharray="3 3" />
         <Area
           onMouseEnter={handleMouseEnter1}
           onMouseLeave={handleMouseLeave1}
           type="monotone"
           dataKey="value"
           stroke="#8884d8"
           fill="url(#gradient1)"
         />
       </AreaChart>
     </ResponsiveContainer>

     <div style={{ marginTop: "10px" }}>
       <FontAwesomeIcon
         icon={faArrowCircleDown}
         style={{ color: "#F90000", height: "28px" }}
       />
     </div>
     <div style={{ color: "#8e918f",  fontSize: 16,fontWeight: "bold",  paddingTop: "5px" }}>
       Drop Off
     </div>
     <div
       style={{
         color: "#F90000",
         paddingTop: "5px",
         fontWeight: "600",
         fontSize: "20px",
       }}
     >
       {drop_off1}%
     </div>
     <div style={graphLineStyle}></div>
   </div>

   <div style={graphContainerStyle1}>
     <div
       style={{
         color: "#8e918f",
         fontSize: 16,
         display: "flex",
         paddingLeft: " 20px",
       }}
     >
       Step 2
     </div>
     <div
       style={{
         display: "flex",
         paddingLeft: " 20px",
         paddingTop: "20px",
         fontSize: "22px",
         fontFamily:"cursive"
       }}
     >
        Underwriting / Quote Generation
     </div>
     <div
       style={{
         display: "flex",
         justifyContent: "space-between",
         padding: " 20px",
         paddingTop: "5px",
       }}
     >
       <span style={{ fontWeight: "bold", fontSize: "25px" }}>
         {formatNumber(First_Cut)}
       </span>
       <span
         style={{ color: "#8e918f", fontSize: "18px",fontWeight:"bold", marginTop: "5px" }}
       >
         {formatNumber(First_Cut_b)} last year
       </span>
     </div>
     <ResponsiveContainer width={350} height={350}>
       <AreaChart data={data1}>
       <Tooltip active={isHovered2} content={<TooltipBar_testDrives sel_year={sel_year}/>} />

         <defs>
           <linearGradient id="gradient2" x1="0" y1="0" x2="0" y2="1">
             <stop offset="5%" stopColor="red" />
             <stop offset="95%" stopColor="maroon" />{" "}
             {/* Change the stopColor here */}
           </linearGradient>
         </defs>
         <YAxis
           domain={[300000, 1500000]}
           ticks={[
             300000, 600000, 900000, 1200000, 1500000,
           ]}
           hide={true}
           tickLine={false}
         />
         <CartesianGrid strokeDasharray="3 3" />
         <Area
           onMouseEnter={handleMouseEnter2}
           onMouseLeave={handleMouseLeave2}
           type="monotone"
           dataKey="value"
           stroke="#8884d8"
           fill="url(#gradient2)"
         />
       </AreaChart>
     </ResponsiveContainer>

     <div style={{ marginTop: "10px" }}>
       <FontAwesomeIcon
         icon={faArrowCircleDown}
         style={{ color: "#F90000", height: "28px" }}
       />
     </div>
     <div style={{ color: "#8e918f",  fontSize: 16,fontWeight: "bold",  paddingTop: "5px" }}>
       Drop Off
     </div>
     <div
       style={{
         color: "#F90000",
         paddingTop: "5px",
         fontWeight: "600",
         fontSize: "20px",
       }}
     >
       {drop_off2}%
     </div>
     <div style={graphLineStyle}></div>
   </div>

   <div style={graphContainerStyle2}>
     <div
       style={{
         color: "#8e918f",
         fontSize: 16,
         display: "flex",
         paddingLeft: " 20px",
       }}
     >
       Step 3
     </div>
     <div
       style={{
         display: "flex",
         paddingLeft: " 20px",
         paddingTop: "20px",
         fontSize: "22px",
         fontFamily:"cursive"
       }}
     >
       Negotiation / Revision
     </div>
     <div
       style={{
         display: "flex",
         justifyContent: "space-between",
         padding: " 20px",
         paddingTop: "5px",
       }}
     >
       <span style={{ fontWeight: "bold", fontSize: "25px" }}>
         {formatNumber(Negotiated_Quote)}
       </span>
       <span
         style={{ color: "#8e918f", fontSize: "18px",fontWeight:"bold", marginTop: "5px" }}
       >
         {formatNumber(Negotiated_Quote_b)} last year
       </span>
     </div>
     <ResponsiveContainer width={350} height={350}>
       <AreaChart data={data2}>
       <Tooltip active={isHovered3} content={<TooltipBar_bk sel_year={sel_year}/>} />

         <defs>
           <linearGradient id="gradient2" x1="0" y1="0" x2="0" y2="1">
             <stop offset="5%" stopColor="red" />
             <stop offset="95%" stopColor="maroon" />
           </linearGradient>
         </defs>
         <YAxis
           domain={[300000, 1500000]}
           ticks={[
             300000, 600000, 900000, 1200000, 1500000,
           ]}
           hide={true}
           tickLine={false}
         />
         <CartesianGrid strokeDasharray="3 3" />
         <Area
           onMouseEnter={handleMouseEnter3}
           onMouseLeave={handleMouseLeave3}
           type="monotone"
           dataKey="value"
           stroke="#8884d8"
           fill="url(#gradient2)"
         />
       </AreaChart>
     </ResponsiveContainer>

     <div style={{ marginTop: "10px" }}>
       <FontAwesomeIcon
         icon={faArrowCircleDown}
         style={{ color: "#F90000", height: "28px" }}
       />
     </div>
     <div style={{ color: "#8e918f",  fontSize: 16,fontWeight: "bold", paddingTop: "5px" }}>
       Drop Off
     </div>
     <div
       style={{
         color: "#F90000",
         paddingTop: "5px",
         fontWeight: "600",
         fontSize: "20px",
       }}
     >
       {drop_off3}%
     </div>
     <div style={graphLineStyle}></div>
   </div>

   <div style={graphContainerStyle3}>
     <div
       style={{
         color: "#8e918f",
         fontSize: 16,
         display: "flex",
         paddingLeft: " 20px",
       }}
     >
       Step 4
     </div>
     <div
       style={{
         display: "flex",
         paddingLeft: " 20px",
         paddingTop: "20px",
         fontSize: "22px",
         fontFamily:"cursive"
       }}
     >
       Payment / Policy Issuance
     </div>
     <div
       style={{
         display: "flex",
         justifyContent: "space-between",
         padding: " 20px",
         paddingTop: "5px",
       }}
     >
       <span style={{ fontWeight: "bold", fontSize: "25px" }}>
         {formatNumber(Total_Policies)}
       </span>
       <span
         style={{ color: "#8e918f", fontSize: "18px",fontWeight:"bold", marginTop: "5px" }}
       >
         {formatNumber(Total_Policies_b)} last year
       </span>
     </div>
     <ResponsiveContainer width={350} height={350}>
       <AreaChart data={data3}>
       <Tooltip active={isHovered4} content={<TooltipBar_ts sel_year={sel_year}/>} />

         <defs>
           <linearGradient id="gradient2" x1="0" y1="0" x2="0" y2="1">
             <stop offset="5%" stopColor="red" />
             <stop offset="95%" stopColor="maroon" />
           </linearGradient>
         </defs>
         <YAxis
           domain={[300000, 1500000]}
           ticks={[
             300000, 600000, 900000, 1200000, 1500000,
           ]}
           hide={true}
           tickLine={false}
         />
         <CartesianGrid strokeDasharray="3 3" />
         <Area
           onMouseEnter={handleMouseEnter4}
           onMouseLeave={handleMouseLeave4}
           type="monotone"
           dataKey="value"
           stroke="#8884d8"
           fill="url(#gradient2)"
         />
       </AreaChart>
     </ResponsiveContainer>

     <div style={{ marginTop: "10px" }}>
       <FontAwesomeIcon
         icon={faCircleCheck}
         style={{ color: "#00C066", height: "28px" }}
       />
     </div>
     <div
       classname="funneldroppoff"
       style={{ color: "#8e918f", fontSize: 16,fontWeight: "bold", paddingTop: "5px" }}
     >
       Conversion
     </div>
     <div
       style={{
         color: "#00C066",
         paddingTop: "5px",
         fontWeight: "600",
         fontSize: "20px",
       }}
     >
       {conversion}%
     </div>
     {/* <div style={graphLineStyle}></div> */}
   </div>
 </div>
</div>
);
};

export default Graph;