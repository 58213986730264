import React, { useState, useEffect } from "react";
import "./style.css";
import Slider from "react-slick";
import { agentsAndBrokers, hdfcMetrics } from "../api/Look";

const Cards = ({ text, label, title, sel_year }) => {
  var settings = {
    dots: false,
    infinite: false,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
  };
  let hdfc_revenue;
  const [hdfcMetricsdata, setHdfcMetricsData] = useState([]);
  const [agentsAndBrokersdata, setAgentsAndBrokersData] = useState([]);
  useEffect(() => {
    const fetchData = async () => {
      try {
        const result = await agentsAndBrokers();
        setAgentsAndBrokersData(result.success);
        // console.log(result.success);
      } catch (error) {
        console.error("Error fetching data:", error);
      }
    };

    fetchData();
  }, []);
  useEffect(() => {
    const fetchData = async () => {
      try {
        const result = await hdfcMetrics();
        setHdfcMetricsData(result.success);
        // console.log(result.success);
      } catch (error) {
        console.error("Error fetching data:", error);
      }
    };

    fetchData();
  }, []);
  function formatNumber(num) {
    return (+num).toLocaleString("en-US");
  }
  hdfcMetricsdata
    .filter((item) => item["data_iter_2.date_year"] === parseInt(sel_year))
    .map((item, index) => {
      hdfc_revenue = (item["data_iter_2.total_revenue"] / 10000000).toFixed(2);
    });
  // dealershipdata.sort(
  //   (a, b) => b["dealership.total_sales"] - a["dealership.total_sales"]
  // );

  return (
    <div style={{ width: "450px", marginLeft: "15px", marginTop: "-297px" }}>
      <Slider {...settings} className="black-slider">
        {agentsAndBrokersdata
          .filter(
            (item, index) =>
              (item["data_iter_2.channel"] == "Agent" ||
                item["data_iter_2.channel"] == "Broker") &&
              item["data_iter_2.date_year"] === parseInt(sel_year)
          )
          .sort(
            (a, b) =>
              b["data_iter_2.total_revenue"] - a["data_iter_2.total_revenue"]
          )
          .slice(0, 12)
          .map((item, index) => (
            <div style={{ width: "400px" }}>
              <div style={{ position: "relative", top: "40px", left: "30px" }}>
                <img
                  src="/HDFC_new1.jpg"
                  style={{
                    width: "80px",
                    height: "60px",
                    border: "1px solid lightgrey",
                    borderRadius: "8px",
                  }}
                  width={200}
                  height={200}
                  alt=""
                />
              </div>
              {/* {parseInt(item["dealership.total_sales"]) > 1000000 && (  */}
              {/* {item==1?(<span className="best-performer">Top Performer</span>):{""} */}
              {index === 0 || index === 1 || index === 2 ? (
                <span className="best-performer">Top Performer</span>
              ) : null}

              <div
                style={{
                  backgroundColor: "white",
                  borderRadius: "10px",
                  padding: "25px",
                }}
              >
                {/* <p className="card-number">{index + 1}</p> */}
                <div style={{ display: "flex", marginTop: "20px" }}>
                  <div style={{ flex: 3 }}>
                    <div style={{ paddingTop: "10px", fontSize: "24px",fontWeight:200 }}>
                      <span className="card-number">{index + 1}</span>.{" "}
                      {item["data_iter_2.region"] +
                        " - " +
                        item["data_iter_2.channel"] +
                        "s"}
                    </div>
                    <div
                      style={{
                        fontSize: "14px",
                        color: "gray",
                        marginTop: "10px",
                      }}
                    ></div>
                  </div>

                  <div
                    style={{
                      flex: 1,
                      // textAlign:'end',
                      borderRadius: "5px",
                    }}
                  >
                    <div
                      style={{
                        backgroundColor: "lightblue",
                        textAlign: "center",
                        borderRadius: "10px",
                        padding: "10px",
                        width: "130px",
                      }}
                    >
                      <div style={{ fontWeight: "600", fontSize: "30px" }}>
                        {(item["data_iter_2.total_revenue"] / 10000000).toFixed(
                          2
                        )}{" "}
                        Cr
                      </div>
                      <div
                        style={{
                          fontSize: "14px",
                          color: "gray",
                          alignItems: "center",
                        }}
                      >
                        Total Premium
                      </div>
                    </div>
                  </div>
                </div>

                <div
                  style={{
                    display: "flex",
                    justifyContent: "space-between",
                    padding: "2px",
                    marginTop: "20px",
                    fontSize: "11px",
                    color: "gray",
                  }}
                >
                  <span style={{ fontSize: "16px" }}>Policies Issued</span>
                  <span style={{ fontSize: "16px" }}>HDFC Premium</span>
                  <span style={{ fontSize: "16px" }}>Contribution</span>
                </div>
                <div
                  style={{
                    display: "flex",
                    justifyContent: "space-between",
                    padding: "2px",
                    fontSize: "13px",
                  }}
                >
                  <span
                    style={{
                      marginTop: "5px",
                      fontSize: "16px",
                      fontWeight: "600",
                    }}
                  >
                    {formatNumber(
                      parseInt(item["data_iter_2.total_policiesIssued"])
                    )}
                  </span>
                  <span
                    style={{
                      marginTop: "5px",
                      fontSize: "16px",
                      fontWeight: "600",
                    }}
                  >
                    {hdfc_revenue} Cr
                  </span>
                  <span
                    style={{
                      marginTop: "5px",
                      fontSize: "16px",
                      fontWeight: "600",
                    }}
                  >
                    {(
                      (item["data_iter_2.total_revenue"] /
                        10000000 /
                        hdfc_revenue) *
                      100
                    ).toFixed(2)}
                    %
                  </span>
                </div>
              </div>
            </div>
          ))}
      </Slider>
    </div>
  );
};

export default Cards;
