import React, { useState, useEffect } from "react";
import "./Main.css";
import styled from "styled-components";
import Doughnut from "./Doughnut";
import { getData, salesTrendZoneWise } from "./api/Look.js";
function formatNumber(num) {
  return (+num).toLocaleString("en-US");
}
const MainLogo = styled.div`
  display: flex;
  align-items: center;
  img {
    width: 55px;
    height: 55px;
    margin-top: 5px;
  }
  margin-top: 20px;
  margin-left: 20px;
`;
const Main = ({ selectedYear, selectedCompany }) => {
  let east = 0,
    west = 0,
    north = 0,
    south = 0,
    total_revenue = 0,
    // HDFC_Ergo = 0,
    // ICICI_Lombard = 0,
    // Bajaj_Allianz = 0,
    insurance_company = 0;
  const [data, Data] = useState([]);
  const [zonedata, setData] = useState([]);
  const filterYear = localStorage.getItem("selectedyear");

  let [hdfc, setHdfc] = useState(0);
  let [icici, setIcici] = useState(0);
  let [bajaj, setBajaj] = useState(0);

  console.log("Main.js sel company", selectedCompany);
  useEffect(() => {
    const fetchData1 = async () => {
      try {
        const result = await getData();
        Data(result.success);
      } catch (error) {
        console.error("Error fetching data:", error);
      }
    };

    fetchData1();
  }, []);

  useEffect(() => {
    const fetchData2 = async () => {
      try {
        const result = await salesTrendZoneWise();
        setData(result.success);
      } catch (error) {
        console.error("Error fetching data:", error);
      }
    };

    fetchData2();
  }, []);
  {
    data
      .filter(
        (item) => item["data_iter_2.date_year"] === parseInt(selectedYear)
      )
      .map((item, index) => {
        switch (item["all_data_iter_4.company"]) {
          case "":
            setHdfc(item["all_data_iter_4.total_revenue"].toFixed(2));
            break;
          case "Maruthi":
            setIcici(item["all_data_iter_4.total_revenue"].toFixed(2));
            break;
          case "Tata":
            setBajaj(item["all_data_iter_4.total_revenue"].toFixed(2));
            break;
        }
      });
  }
  if (selectedCompany == "Mahindra") {
    insurance_company = "HDFC Ergo";
  } else if (selectedCompany == "Tata") {
    insurance_company = "ICICI Lombard";
  } else {
    insurance_company = "Bajaj Allianz";
  }
  {
    zonedata
      .filter(
        (item) =>
          item["data_iter_2.date_year"] === parseInt(selectedYear) &&
          item["data_iter_2.insurance_company"] === insurance_company
      )
      .map((item, index) => {
        switch (item["data_iter_2.zone"]) {
          case "East":
            east = Number(
              (item["data_iter_2.total_revenue"] / 10000000).toFixed(2)
            );
            break;
          case "West":
            west = Number(
              (item["data_iter_2.total_revenue"] / 10000000).toFixed(2)
            );
            break;
          case "North":
            north = Number(
              (item["data_iter_2.total_revenue"] / 10000000).toFixed(2)
            );
            break;
          case "South":
            south = Number(
              (item["data_iter_2.total_revenue"] / 10000000).toFixed(2)
            );
            break;
        }
      });
  }
  total_revenue = (east + west + north + south).toFixed(2);
  //console.log('type of rev',typeof(total_revenue))
  const [selectedData, setSelectedData] = useState("2021");

  const selectyearhandler = (event) => {
    setSelectedData(event.target.value);
  };
  //localStorage.setItem("selectedyear", selectedData);
  return (
    <>
      <div className="main">
        <div className={`${selectedCompany}-circle circle`}>
          <div className="circle-2">
            <div class="timeline2">
              <div class="outers">
                <div class="cards">
                  <div
                    class="info1"
                    style={{ display: "flex", flexDirection: "column" }}
                  >
                    <div>
                      <p
                        className={`${selectedCompany} title`}
                        style={{ whiteSpace: "nowrap" }}
                      ></p>
                      <p class="title" style={{ whiteSpace: "nowrap" }}>
                        Total Premium
                      </p>
                    </div>
                    {data
                      .filter(
                        (item) =>
                          item["data_iter_2.date_year"] ===
                          parseInt(selectedYear)
                      )
                      .map((item, index) => {
                        switch (item["data_iter_2.insurance_company"]) {  
                          case "HDFC Ergo":
                            hdfc = 
                              Number((item["data_iter_2.total_revenue"] / 10000000) .toFixed(2)
                              );
                            break;
                          case "ICICI Lombard":
                            icici =  Number((item["data_iter_2.total_revenue"] / 10000000) .toFixed(2)
                            );
                            break;
                          case "Bajaj Allianz":
                            bajaj =  Number((item["data_iter_2.total_revenue"] / 10000000) .toFixed(2)
                            );
                            break;
                        }
                      })}

                    <h3 style={{ whiteSpace: "nowrap" }}>
                      ₹ {formatNumber(total_revenue)} Cr
                    </h3>
                  </div>
                </div>
                <div class="cards">
                  <div
                    class="info1"
                    style={{ display: "flex", flexDirection: "column" }}
                  >
                    <div>
                      <p
                        className={`${selectedCompany} title`}
                        style={{ whiteSpace: "nowrap" }}
                      ></p>
                    </div>
                    <p class="title" style={{ whiteSpace: "nowrap" }}>
                      Eastern Zone
                    </p>

                    <h3 style={{ whiteSpace: "nowrap" }}>
                      ₹ {formatNumber(east)} Cr
                    </h3>
                  </div>
                </div>
                <div class="cards">
                  <div
                    class="info1"
                    style={{ display: "flex", flexDirection: "column" }}
                  >
                    <div>
                      <p
                        className={`${selectedCompany} title`}
                        style={{ whiteSpace: "nowrap" }}
                      ></p>
                      <p class="title" style={{ whiteSpace: "nowrap" }}>
                        Western Zone
                      </p>
                    </div>

                    <h3 style={{ whiteSpace: "nowrap" }}>
                      ₹ {formatNumber(west)} Cr
                    </h3>
                  </div>
                </div>
                <div class="cards">
                  <div
                    class="info1"
                    style={{ display: "flex", flexDirection: "column" }}
                  >
                    <div>
                      <p
                        className={`${selectedCompany} title`}
                        style={{ whiteSpace: "nowrap" }}
                      ></p>
                      <p class="title" style={{ whiteSpace: "nowrap" }}>
                        Northern Zone
                      </p>
                    </div>

                    <h3 style={{ whiteSpace: "nowrap" }}>
                      ₹ {formatNumber(north)} Cr
                    </h3>
                  </div>
                </div>
                <div class="cards">
                  <div
                    class="info1"
                    style={{ display: "flex", flexDirection: "column" }}
                  >
                    <div>
                      <p
                        className={`${selectedCompany} title`}
                        style={{ whiteSpace: "nowrap" }}
                      ></p>
                      <p class="title" style={{ whiteSpace: "nowrap" }}>
                        Southern Zone
                      </p>
                    </div>

                    <h3 style={{ whiteSpace: "nowrap" }}>
                      ₹ {formatNumber(south)} Cr
                    </h3>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="circle-3">
            <Doughnut
              hdfcErgo={formatNumber(hdfc)}
              bajajAllianz={formatNumber(bajaj)}
              iciciLombard={formatNumber(icici)}
            />
          </div>

          <div className={`${selectedCompany}-circles circle-4`}>
            <div class="timeline">
              <div class="card1">
                <div class="info">
                  <p style={{ whiteSpace: "nowrap" }}>HDFC Ergo</p>
                  <h3 style={{ whiteSpace: "nowrap" }}>₹ {hdfc} Cr </h3>
                </div>
              </div>
              <div class="card2">
                <div class="info">
                  <p style={{ whiteSpace: "nowrap" }}>BAJAJ Allianz</p>
                  <h3 style={{ whiteSpace: "nowrap" }}>₹ {bajaj} Cr </h3>
                </div>
              </div>
              <div class="card3">
                <div class="info">
                  <p style={{ whiteSpace: "nowrap" }}>ICICI Lombard</p>
                  <h3 style={{ whiteSpace: "nowrap" }}>₹ {icici} Cr </h3>
                </div>
              </div>
            </div>
          </div>
          <div className="circle-5">
            <MainLogo>
              <img
                src={
                  selectedCompany === "Maruthi"
                    ? "/BAJAJ.png"
                    : selectedCompany === "Tata"
                    ? "/icici_new1.png"
                    : "/HDFC_new1.jpg"
                }
                alt="logo"
              />
            </MainLogo>
          </div>
        </div>
      </div>
    </>
  );
};

export default Main;
