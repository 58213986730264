import React from "react";
import "./styles.css"

const UserChatIcon = () => {
    return (
        <div className="profile-icon1" title="Finance">
            <span className="initial1">C</span>
        </div>
    );
};
export default UserChatIcon;