import React, { useEffect, useRef, useState } from "react";
import "./style.css";
import HalfpieChart from "./halfpiechart";
import FilterAltOutlinedIcon from "@mui/icons-material/FilterAltOutlined";
import { hdfcMetrics, policyPremium, claimsPremium } from "../api/Look.js";
import { isMuiElement } from "@mui/material";
import { useNavigate } from "react-router-dom";
//import { totalSales } from "../../../backend/service/user.service.js";

const Leftcard = ({ sel_year, north, south, east, west }) => {
  let hdfc_profit = 0,
    hdfc_revenue = 0,
    hdfc_policies,
    avg_icr;
  //const sel_year=2022;
  let policy1, policy2, policy3, policy4, policy5;
  let policy1_policies = 0,
    policy2_policies = 0,
    policy3_policies = 0,
    policy4_policies = 0,
    policy5_policies = 0;
  let policy1_premium = 0,
    policy2_premium = 0,
    policy3_premium = 0,
    policy4_premium = 0,
    policy5_premium = 0;
  let policy1_cont = 0,
    policy2_cont = 0,
    policy3_cont = 0,
    policy4_cont = 0,
    policy5_cont = 0;
  const [policyPremiumdata, setPolicyPremiumData] = useState([]);
  const [hdfcMetricsdata, setHdfcMetricsData] = useState([]);
  const [claimsPremiumdata, setClaimsPremiumData] = useState([]);
  const clickCount = useRef(0);
  const navigate = useNavigate();

  // const [policy1Cont, setPolicy1Cont] = useState(0);
  // const [policy2Cont, setPolicy2Cont] = useState(0);
  // const [policy3Cont, setPolicy3Cont] = useState(0);
  // const [policy4Cont, setPolicy4Cont] = useState(0);
  // const [policy5Cont, setPolicy5Cont] = useState(0);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const result = await hdfcMetrics();
        setHdfcMetricsData(result.success);
      } catch (error) {
        console.error("Error fetching data:", error);
      }
    };

    fetchData();
  }, []);
  useEffect(() => {
    const fetchData = async () => {
      try {
        const result = await claimsPremium();
        setClaimsPremiumData(result.success);
      } catch (error) {
        console.error("Error fetching data:", error);
      }
    };

    fetchData();
  }, []);
  {
    hdfcMetricsdata
      .filter((item) => item["data_iter_2.date_year"] === parseInt(sel_year))
      .map((item, index) => {
        hdfc_revenue = (item["data_iter_2.total_revenue"] / 10000000).toFixed(
          2
        );
        hdfc_policies = item["data_iter_2.total_policiesIssued"];
        hdfc_profit = (item["data_iter_2.total_profit"] / 10000000).toFixed(2);
      });
  }
  useEffect(() => {
    const fetchData = async () => {
      try {
        const result = await policyPremium();
        setPolicyPremiumData(result.success);
      } catch (error) {
        console.error("Error fetching data:", error);
      }
    };

    fetchData();
  }, []);
  {
    policyPremiumdata
      .filter((item) => item["data_iter_2.date_year"] === parseInt(sel_year))
      .sort(
        (a, b) =>
          b["data_iter_2.total_revenue"] - a["data_iter_2.total_revenue"]
      )
      .slice(0, 5)
      .map((item, index) => {
        switch (index) {
          case 0:
            policy1 = item["data_iter_2.policy_name"];
            policy1_premium = parseInt(
              item["data_iter_2.total_revenue"] / 10000000
            );
            policy1_policies = item["data_iter_2.total_policiesIssued"];
            policy1_cont = ((policy1_premium / hdfc_revenue) * 100).toFixed(2);
            break;
          case 1:
            policy2 = item["data_iter_2.policy_name"];
            policy2_premium = parseInt(
              item["data_iter_2.total_revenue"] / 10000000
            );
            policy2_policies = item["data_iter_2.total_policiesIssued"];
            policy2_cont = ((policy2_premium / hdfc_revenue) * 100).toFixed(2);
            break;
          case 2:
            policy3 = item["data_iter_2.policy_name"];
            policy3_premium = parseInt(
              item["data_iter_2.total_revenue"] / 10000000
            );
            policy3_policies = item["data_iter_2.total_policiesIssued"];
            policy3_cont = ((policy3_premium / hdfc_revenue) * 100).toFixed(2);
            break;
          case 3:
            policy4 = item["data_iter_2.policy_name"];
            policy4_premium = parseInt(
              item["data_iter_2.total_revenue"] / 10000000
            );
            policy4_policies = item["data_iter_2.total_policiesIssued"];
            policy4_cont = ((policy4_premium / hdfc_revenue) * 100).toFixed(2);
            break;
          case 4:
            policy5 = item["data_iter_2.policy_name"];
            policy5_premium = parseInt(
              item["data_iter_2.total_revenue"] / 10000000
            );
            policy5_policies = item["data_iter_2.total_policiesIssued"];
            policy5_cont = ((policy5_premium / hdfc_revenue) * 100).toFixed(2);
            break;
        }
      });
  }

  claimsPremiumdata
    .filter((item) => item["data_iter_2.date_year"] === parseInt(sel_year))
    .map((item) => {
      avg_icr = (item["data_iter_2.Average_ICR"] * 100).toFixed(2);
    });

  const handleButtonCLick = () => {
    clickCount.current += 1;
    console.log("Click count");

    if (clickCount.current === 2) {
      navigate("/Funnel");
      console.log("If double clicked");
    }
  };
  function formatNumber(num) {
    return (+num).toLocaleString("en-US");
  }
  return (
    <>
      <div style={{ padding: "10px" }}>
        <div
          style={{
            // marginBottom: "1rem",
            fontSize: "26px",
            fontWeight: "500",
            marginRight: "60px",
          }}
        >
          Total Premium
        </div>
        <div
          style={{
            marginBottom: "0.2rem",
            fontSize: "38px",
            fontWeight: "600",
          }}
        >
          {hdfc_revenue} Cr
        </div>
        <div>
          <div style={{ display: "flex" }}>
            <div style={{ flex: 1 }}>
              <div
                style={{ fontSize: "16px", fontWeight: "900", color: "grey" }}
              >
                Policies Issued
              </div>
              <div
                style={{
                  marginTop: "5px",
                  fontWeight: "900",
                  fontSize: "20px",
                }}
              >
                {formatNumber(hdfc_policies)}
              </div>
            </div>
            <div style={{ flex: 1.6, marginLeft: "45px" }}>
              <div
                style={{
                  fontSize: "16px",
                  fontWeight: "900",
                  color: "grey",
                  marginLeft: "25px",
                }}
              >
                Profit
              </div>
              <div
                style={{
                  marginTop: "5px",
                  fontWeight: "900",
                  fontSize: "20px",
                  marginLeft: "25px",
                }}
              >
                {hdfc_profit} Cr
              </div>
            </div>
            <div style={{ flex: 1.6 }}>
              <div
                style={{ fontSize: "16px", fontWeight: "900", color: "grey" }}
              >
                Avg ICR
              </div>
              <div
                style={{
                  marginTop: "5px",
                  fontWeight: "900",
                  fontSize: "20px",
                }}
              >
                {avg_icr} %
              </div>
            </div>
          </div>
          {/* <div style={{display:"flex"}}>
    
      <span>+10%</span>
      <h5>10,282 Cr</h5>
      <span>+10%</span>
      </div> */}
        </div>
      </div>
      <div
        style={{
          // backgroundColor: "white",
          width: "100%",
          // height: "430px",
          borderRadius: "10px",
          // padding: "10px",
        }}
      >
        <div style={{ padding: "0px  10px 0px" }}>
          <div
            style={{
              backgroundColor: "white",
              padding: "10px",
              borderRadius: "10px",
              width: "435px",
              height: "430px",
            }}
            className="sales-leftcard-box"
            // onClick={handleButtonCLick}
          >
            <div
              style={{
                display: "flex",
                justifyContent: "space-between",
                paddingBottom: "10px",
                fontSize: "13px",
              }}
            >
              <span
                style={{
                  display: "flex",
                  alignItems: "center",
                  fontSize: "18px",
                  fontWeight: "600",
                  color: "grey",
                }}
              >
                Top 5 Policies
              </span>
              {/* <span style={{ display: "flex", alignItems: "center" }}>
                <div>
                  <FilterAltOutlinedIcon />
                </div>
                <div>filter</div>
              </span> */}
            </div>
            <div
              style={{
                backgroundColor: "#E5F6FB",
                height: "25px",
                display: "flex",
                justifyContent: "space-between",
                alignItems: "center",
                fontSize: "11px",
                color: "black",
              }}
            >
              <div></div>
              <div style={{ fontSize: "15px", fontWeight: "bold" }}>Policy</div>
              <div style={{ fontSize: "15px", fontWeight: "bold" }}>
                Premium
              </div>
              <div style={{ fontSize: "15px", fontWeight: "bold" }}>
                No of Policies
              </div>
              <div style={{ fontSize: "15px", fontWeight: "bold" }}>
                Contribution
              </div>
            </div>
            <div
              style={{
                height: "35px",
                display: "flex",
                // justifyContent: "space-between",
                // marginRight: "10px",
                alignItems: "center",
                // textAlign: "left",
                borderBottom: "1px solid #ccc", // Gray color border
                // borderRadius: "0px 0px 5px 5px",
                fontSize: "12px",
              }}
            >
              <span
                style={{
                  width: "5px",
                  height: "20px",
                  backgroundColor: "orange",
                  marginRight:"5px",
                  display: "inline-block",
                }}
              ></span>
              <span
                className="policy-content"
                style={{ color: "gray", fontSize: "16px", marginRight: "15px" }}
              >
                {policy1}
              </span>
              <span
                style={{
                  fontSize: "16px",
                  fontWeight: "800",
                  color: "black",
                  marginRight: "4rem",
                }}
              >
                {policy1_premium} Cr
              </span>
              <span
                style={{ color: "gray", fontSize: "16px", marginRight: "5rem" }}
              >
                {formatNumber(policy1_policies)}
              </span>
              <span style={{ color: "gray", fontSize: "16px" }}>
                {policy1_cont}%
              </span>
            </div>
            <div
              style={{
                height: "35px",
                display: "flex",
                // justifyContent: "space-between",
                alignItems: "center",
                borderBottom: "1px solid #ccc", // Gray color border
                // borderRadius: "0px 0px 5px 5px",
                fontSize: "12px",
              }}
            >
              <span
                style={{
                  width: "5px",
                  height: "20px",
                  backgroundColor: "red",
                  marginRight:"5px",
                  display: "inline-block",
                }}
              ></span>
              <span
                className="policy-content"
                style={{ color: "gray", fontSize: "16px", marginRight: "10px" }}
              >
                {policy2}
              </span>
              <span
                style={{
                  fontSize: "16px",
                  fontWeight: "800",
                  color: "black",
                  marginRight: "4rem",
                }}
              >
                {policy2_premium} Cr
              </span>
              <span
                style={{
                  color: "gray",
                  fontSize: "16px",
                  marginRight: "4.5rem",
                }}
              >
                {formatNumber(policy2_policies)}
              </span>
              <span style={{ color: "gray", fontSize: "16px" }}>
                {policy2_cont}%
              </span>
            </div>
            <div
              style={{
                height: "35px",
                display: "flex",
                // justifyContent: "space-between",
                alignItems: "center",
                borderBottom: "1px solid #ccc", // Gray color border
                // borderRadius: "0px 0px 5px 5px",
                fontSize: "12px",
              }}
            >
              <span
                className="policy-content"
                style={{
                  width: "5px",
                  height: "20px",
                  backgroundColor: "skyblue",
                  marginRight:"5px",
                  display: "inline-block",
                }}
              ></span>
              <span
                style={{ color: "gray", fontSize: "16px", marginRight: "10px" }}
              >
                {policy3}
              </span>
              <span
                style={{
                  fontSize: "16px",
                  fontWeight: "800",
                  color: "black",
                  marginRight: "4rem",
                }}
              >
                {policy3_premium} Cr
              </span>
              <span
                style={{
                  color: "gray",
                  fontSize: "16px",
                  marginRight: "4.5rem",
                }}
              >
                {formatNumber(policy3_policies)}
              </span>
              <span style={{ color: "gray", fontSize: "16px" }}>
                {policy3_cont}%
              </span>
            </div>
            <div
              style={{
                height: "35px",
                display: "flex",
                // justifyContent: "space-between",
                alignItems: "center",
                borderBottom: "1px solid #ccc", // Gray color border
                // borderRadius: "0px 0px 5px 5px",
                fontSize: "12px",
              }}
            >
              <span
                style={{
                  width: "5px",
                  height: "20px",
                  backgroundColor: "purple",
                  marginRight:"5px",
                  display: "inline-block",
                }}
              ></span>
              <span
                className="policy-content"
                style={{ color: "gray", fontSize: "16px", marginRight: "20px" }}
              >
                {policy4}
              </span>
              <span
                style={{
                  fontSize: "16px",
                  fontWeight: "800",
                  color: "black",
                  marginLeft: "25px",
                  marginRight: "4rem",
                }}
              >
                {policy4_premium} Cr
              </span>
              <span
                style={{
                  color: "gray",
                  fontSize: "16px",
                  marginRight: "4.5rem",
                }}
              >
                {formatNumber(policy4_policies)}
              </span>
              <span style={{ color: "gray", fontSize: "16px" }}>
                {policy4_cont}%
              </span>
            </div>
            <div
              style={{
                height: "35px",
                display: "flex",
                // justifyContent: "space-between",
                alignItems: "center",
                borderBottom: "1px solid #ccc", // Gray color border
                // borderRadius: "0px 0px 5px 5px",
                fontSize: "12px",
              }}
            >
              <span
                style={{
                  width: "5px",
                  height: "20px",
                  backgroundColor: "green",
                  display: "inline-block",
                }}
              ></span>
              <span
                className="policy-content"
                style={{ color: "gray", fontSize: "16px", marginRight: "2rem" }}
              >
                 Home Shield
              </span>
              <span
                style={{
                  fontSize: "16px",
                  fontWeight: "800",
                  color: "black",
                  marginRight: "4.2rem",
                }}
              >
                {policy5_premium} Cr
              </span>
              <span
                style={{
                  color: "gray",
                  fontSize: "16px",
                  marginRight: "4.6rem",
                }}
              >
                {formatNumber(policy5_policies)}
              </span>
              <span style={{ color: "gray", fontSize: "16px" }}>
                {policy5_cont}%
              </span>
            </div>
            <div>
              <HalfpieChart
                north={north}
                south={south}
                east={east}
                west={west}
                policy1={policy1_cont}
                policy2={policy2_cont}
                policy3={policy3_cont}
                policy4={policy4_cont}
                policy5={policy5_cont}
              />
            </div>
            <div
              style={{
                display: "flex",
                textAlign: "center",
                justifyContent: "space-between",
                alignItems: "center",
                fontSize: "12px",
              }}
            >
              <div style={{ flex: 1, display: "flex", marginTop: "20px" }}>
                <div
                  style={{
                    backgroundColor: "#4A0404",
                    height: "10px",
                    borderRadius: "10px",
                    width: "10px",
                    marginRight: "10px",
                    marginTop: "4px",
                  }}
                ></div>
                <div style={{ fontSize: "16px" }}>{policy1}</div>
              </div>
              <div style={{ flex: 1, display: "flex", marginTop: "20px" }}>
                <div
                  style={{
                    backgroundColor: "#770737",
                    height: "10px",
                    borderRadius: "10px",
                    width: "10px",
                    marginRight: "10px",
                    marginTop: "4px",
                  }}
                ></div>
                <div style={{ fontSize: "16px" }}>{policy2}</div>
              </div>
              <div style={{ flex: 1, display: "flex", marginTop: "20px" }}>
                <div
                  style={{
                    backgroundColor: "#E0115F",
                    height: "10px",
                    borderRadius: "10px",
                    width: "10px",
                    marginRight: "10px",
                    marginTop: "4px",
                  }}
                ></div>
                <div style={{ fontSize: "16px" }}>{policy3}</div>
              </div>
            </div>
            <div
              style={{
                display: "flex",
                textAlign: "center",
                marginBottom: "20px",
                justifyContent: "space-between",
                alignItems: "center",
                fontSize: "12px",
              }}
            >
              <div style={{ flex: 1, display: "flex", marginTop: "20px" }}>
                <div
                  style={{
                    backgroundColor: "#E30B5C",
                    height: "10px",
                    borderRadius: "10px",
                    width: "10px",
                    marginRight: "10px",
                    marginTop: "4px",
                  }}
                ></div>
                <div style={{ fontSize: "16px" }}>{policy4}</div>
              </div>
              <div style={{ flex: 1, display: "flex", marginTop: "20px" }}>
                <div
                  style={{
                    backgroundColor: "#E30B5C",
                    height: "10px",
                    borderRadius: "10px",
                    width: "10px",
                    marginRight: "10px",
                    marginTop: "4px",
                  }}
                ></div>
                <div style={{ fontSize: "16px" }}>{policy5}</div>
              </div>
              <div style={{ flex: 1, display: "flex", marginTop: "20px" }}>
                {/* <div style={{ backgroundColor: 'red', height: '10px', borderRadius:'10px', width:'10px',marginRight:'5px'}}></div>
           <div style={{ fontSize:'16px' }}>Scorpio</div> */}
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default Leftcard;
