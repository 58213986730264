import "./RightSideBar.css";
import FileDownloadOutlinedIcon from "@mui/icons-material/FileDownloadOutlined";

import React, { useState, useEffect } from "react";
import AddIcon from "@mui/icons-material/Add";
import { getData, totalSales, salesTrend } from "./api/Look.js";
import Exporter from "./DownloadPage.js";

import InputLabel from "@mui/material/InputLabel";
import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";
import Select, { SelectChangeEvent } from "@mui/material/Select";

import {
  LineChart,
  Line,
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip,
  Legend,
  ResponsiveContainer,
} from "recharts";

let hdfc_2021,
  icici_2021,
  bajaj_2021,
  hdfc_2022,
  icici_2022,
  bajaj_2022,
  hdfc_2023,
  icici_2023,
  bajaj_2023;
let sales, rev, cont;
const RightSideBar = ({ updateSelectedCompany }) => {
  //const [selectedCompany, setSelectedCompany]=useState("Mahindra")
  const [linegraphdata, setData] = useState([]);
  const [salesdata, salesData] = useState([]);
  const [salestrend, salesTrendData] = useState([]);
  const [selectedVehicle, setSelectedVehicle] = useState("Property");
  const [selectedYear, setSelectedYear] = useState("2023");
  const [selectedZone, setSelectedZone] = useState("East");

  const [opacity, setOpacity] = useState({
    HDFC: 1,
    Bajaj: 1,
    ICICI: 1,
  });
  let sales, rev, cont;
  sales = rev = cont = 0;
  useEffect(() => {
    const fetchData = async () => {
      try {
        const result = await getData();
        setData(result.success);
      } catch (error) {
        console.error("Error fetching data:", error);
      }
    };
    fetchData();
  }, []);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const result = await totalSales();
        salesData(result.success);
      } catch (error) {
        console.error("Error fetching data:", error);
      }
    };
    fetchData();
  }, []);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const result = await salesTrend();
        salesTrendData(result.success);
      } catch (error) {
        console.error("Error fetching data:", error);
      }
    };
    fetchData();
  }, []);

  function formatNumber(num) {
    return (+num).toLocaleString("en-US");
  }

  const selectCompanyHandler = (event) => {
    const newCompany = event.target.value;
    //setSelectedCompany(newCompany)
    updateSelectedCompany(newCompany);
  };
  const onVehicleHandler = (event) => {
    setSelectedVehicle(event.target.value);
  };
  const onYearHandler = (event) => {
    setSelectedYear(event.target.value);
  };
  const onZoneHandler = (event) => {
    setSelectedZone(event.target.value);
  };

  linegraphdata
    .filter((item) => item["data_iter_2.date_year"] === 2021)
    .map((item, index) => {
      switch (item["data_iter_2.insurance_company"]) {
        case "HDFC Ergo":
          hdfc_2021 = item["data_iter_2.total_revenue"];
          break;
        case "ICICI Lombard":
          icici_2021 = item["data_iter_2.total_revenue"];
          break;
        case "Bajaj Allianz":
          bajaj_2021 = item["data_iter_2.total_revenue"];
          break;
      }
    });
  linegraphdata
    .filter((item) => item["data_iter_2.date_year"] === 2022)
    .map((item, index) => {
      switch (item["data_iter_2.insurance_company"]) {
        case "HDFC Ergo":
          hdfc_2022 = item["data_iter_2.total_revenue"];
          break;
        case "ICICI Lombard":
          icici_2022 = item["data_iter_2.total_revenue"];
          break;
        case "Bajaj Allianz":
          bajaj_2022 = item["data_iter_2.total_revenue"];
          break;
      }
    });
  linegraphdata
    .filter((item) => item["data_iter_2.date_year"] === 2023)
    .map((item, index) => {
      switch (item["data_iter_2.insurance_company"]) {
        case "HDFC Ergo":
          hdfc_2023 = item["data_iter_2.total_revenue"];
          break;
        case "ICICI Lombard":
          icici_2023 = item["data_iter_2.total_revenue"];
          break;
        case "Bajaj Allianz":
          bajaj_2023 = item["data_iter_2.total_revenue"];
          break;
      }
    });
  const data = [
    {
      name: "2021",
      HDFC_Ergo: hdfc_2021,
      ICICI: icici_2021,
      Bajaj: bajaj_2021,
    },
    {
      name: "2022",
      HDFC_Ergo: hdfc_2022,
      ICICI: icici_2022,
      Bajaj: bajaj_2022,
    },
    {
      name: "2023",
      HDFC_Ergo: hdfc_2023,
      ICICI: icici_2023,
      Bajaj: bajaj_2023,
    },
  ];
  return (
    <>
      <div className="rightsidebar">
        <div className="rightmanage">
          <div className="rightbar__topcontainer">
            <div className="rightbar__top">
              <p style={{ whiteSpace: "nowrap" }}>Competitor Analysis</p>
              <div className="topicons">
                <Exporter />
              </div>
            </div>
            <div className="rightbar__options">
              <div className="optionscontainer">
                <p>Company</p>
              </div>
            </div>
            <div className="radiobutton">
              <div className="first-line">
                <input
                  onChange={selectCompanyHandler}
                  type="radio"
                  id="mahindra"
                  name="brand_name"
                  value="Mahindra"
                  defaultChecked
                />
                <label htmlFor="html">HDFC Ergo</label>
                <input
                  onChange={selectCompanyHandler}
                  type="radio"
                  id="tata"
                  name="brand_name"
                  value="Tata"
                />
                <label htmlFor="css">ICICI Lombard</label>
              </div>
              <div className="second-line">
                <input
                  onChange={selectCompanyHandler}
                  type="radio"
                  id="maruthi"
                  name="brand_name"
                  value="Maruthi"
                />
                <label htmlFor="Maruthi">Bajaj Allianz</label>
              </div>
            </div>
            <hr style={{ width: "353px" }}></hr>
            <div className="rightbarbottomcontainer">
              <div className="brandname">
                <p>HDFC Ergo</p>
              </div>

              <div className="rightbarbottom__options">
                <div className="">
                  {/* <select
                    name="vehiles"
                    id="vehicle"
                    onChange={onVehicleHandler}
                  >
                    <option value="Property">Property</option>
                    <option value="Motor">Motor</option>
                    <option value="Health">Health</option>
                    <option value="Miscellaneous">Others</option>
                  </select> */}
                  <FormControl
                    sx={{ m: 0, minWidth: 117, marginRight: 3, marginLeft: 1 }}
                    size="small"
                  >
                    <InputLabel id="demo-select-small-label">LOB</InputLabel>
                    <Select
                      name="lob"
                      labelId="demo-select-small-label"
                      id="demo-select-small"
                      value={selectedVehicle}
                      label="lob"
                      onChange={onVehicleHandler}
                    >
                      <MenuItem value="Property">Property</MenuItem>
                      <MenuItem value="Motor">Motor</MenuItem>
                      <MenuItem value="Health">Health</MenuItem>
                      <MenuItem value="Miscellaneous">Others</MenuItem>
                    </Select>
                  </FormControl>
                </div>
                <div>
                  {/* <div className="" >
                    
      <FormControl sx={{ m: 0, minWidth: 117 }} size="small">
      <InputLabel id="demo-select-small-label">Year</InputLabel>
      <Select
      name="year"
        labelId="demo-select-small-label"
        id="demo-select-small"
        value={selectedYear}
        label="year"
        onChange={onYearHandler}
      >
        <MenuItem value="2023">2023</MenuItem>
        <MenuItem value="2022">2022</MenuItem>
        <MenuItem value="2021">2021</MenuItem>
        
      </Select>
    </FormControl>
                  </div> */}
                </div>

                <div className="">
                  <FormControl sx={{ m: 0, minWidth: 117 }} size="small">
                    <InputLabel id="demo-select-small-label">Region</InputLabel>
                    <Select
                      name="region"
                      labelId="demo-select-small-label"
                      id="demo-select-small"
                      value={selectedZone}
                      label="region"
                      onChange={onZoneHandler}
                    >
                      <MenuItem value={"East"}>East</MenuItem>
                      <MenuItem value={"West"}>West</MenuItem>
                      <MenuItem value={"North"}>North</MenuItem>
                      <MenuItem value={"South"}>South</MenuItem>
                    </Select>
                  </FormControl>
                </div>
              </div>
              <div className="data">
                <div className="datafirst-line">
                  {salesdata
                    .filter(
                      (item) =>
                        item["data_iter_2.date_year"] ===
                          parseInt(selectedYear) &&
                        item["data_iter_2.lob"] == selectedVehicle &&
                        item["data_iter_2.zone"] == selectedZone
                    )
                    .map((item, index) => {
                      switch (item["data_iter_2.date_year"]) {
                        case 2023:
                          sales = item["data_iter_2.total_revenue"] / 10000000;
                          rev = hdfc_2023 / 10000000;
                          cont = (sales / rev) * 100;
                          break;
                        case 2022:
                          sales = item["data_iter_2.total_revenue"] / 10000000;
                          rev = hdfc_2022 / 10000000;
                          cont = (sales / rev) * 100;
                          break;
                        case 2021:
                          sales = item["data_iter_2.total_revenue"] / 10000000;
                          rev = hdfc_2021 / 10000000;
                          cont = (sales / rev) * 100;
                          break;
                      }
                    })}
                  <p>Premium Received</p>
                </div>
                <div className="datasecond-line">
                  <h4>₹ {formatNumber(sales ? sales.toFixed(2) : 0)} Cr</h4>
                </div>
                <div className="datafirst-line" style={{ marginTop: "10px" }}>
                  {/* <p style={{ whiteSpace: "nowrap" }}>Total revenue</p> */}
                  <p>Contribution</p>
                </div>
                <div className="datasecond-line">
                  {/* <h4 style={{ whiteSpace: "nowrap" }}>
                    ₹ {formatNumber(rev ? rev.toFixed(2) : 0)} Cr
                  </h4> */}
                  <h4 style={{ whiteSpace: "nowrap" }}>
                    {cont ? cont.toFixed(1) : 0}%
                  </h4>
                </div>
              </div>
            </div>
          </div>
        </div>

        {/* LINE GRAPH */}
        <div className="competitorgraph" style={{ width: "100%" }}>
          <p>Competitor Premium Trend</p>
          <ResponsiveContainer width="100%" height={200}>
            <LineChart
              width={500}
              height={200}
              data={data}
              margin={{
                top: 20,
                right: 20,
                left: 20,
                bottom: 0,
              }}
              fill="transparent"
            >
              <XAxis dataKey="name" />
              <Tooltip formatter={(value, name) => [`${(value/10000000).toFixed(2)} Cr`, name]}/>
              {/* <Legend /> */}
              <Line
                type="monotone"
                dataKey="HDFC_Ergo"
                strokeOpacity={opacity.HDFC}
                stroke="#FA5775"
                activeDot={{ r: 8 }}
              />
              <Line
                type="monotone"
                dataKey="ICICI"
                strokeOpacity={opacity.ICICI}
                stroke="#6DCAFE"
              />
              <Line
                type="monotone"
                dataKey="Bajaj"
                strokeOpacity={opacity.Bajaj}
                stroke="#BB79E2"
              />
            </LineChart>
          </ResponsiveContainer>
          {/* <p style={{ whiteSpace: "nowrap" }}>
            <span className="year">2021 </span>
            <span className="year">2022 </span>
            <span className="year">2023 </span>
          </p> */}
        </div>
      </div>
    </>
  );
};
export default RightSideBar;
