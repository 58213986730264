
import React, { useState } from "react";
import MoreVertIcon from "@mui/icons-material/MoreVert";
import "../App.css";
import Graph from "./Graph";

import InputLabel from "@mui/material/InputLabel";
import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";
import Select, { SelectChangeEvent } from "@mui/material/Select";

function Funnel() {
  const [sel_year, updateYear] = useState("2023");
  const handleyearchange = (event) => {
    const year = event.target.value;
    updateYear(year);
  };
  return (
    <>
      <div className="funnel-container">
        <div className="funnel">
          <div className="funnel1">Conversion Funnel Analysis</div>
        </div>
        <div className="rightsidecontain">
          <div className="dropdown">
            {/* <select name="year" id="years" onChange={handleyearchange}>
              <option value="2023">2023</option>
              <option value="2022">2022</option>
              <option value="2021">2021</option>
            </select> */}
            <FormControl sx={{ m: 1, minWidth: 120 }} size="small">
              <InputLabel id="demo-select-small-label">Year</InputLabel>
              <Select
                name="year"
                labelId="demo-select-small-label"
                id="demo-select-small"
                label="Year"
                value={sel_year}
                onChange={handleyearchange}
              >
                <MenuItem value={2023}>2023</MenuItem>
                <MenuItem value={2022}>2022</MenuItem>
                <MenuItem value={2021}>2021</MenuItem>
              </Select>
            </FormControl>
          </div>
          {/* <div className="more-vert-icon">
            <MoreVertIcon />
          </div> */}
        </div>
      </div>
      <Graph sel_year={sel_year} />
      <div>
        <p className="copyRightParagraph">
          Copyright @ 2023 HDFC Ergo. All rights reserved.
        </p>
      </div>
    </>
  );
}

export default Funnel;
