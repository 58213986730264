import React, { useState, useEffect } from "react";
import {
  LineChart,
  Line,
  XAxis,
  YAxis,
  Tooltip,
  Legend,
  ResponsiveContainer,
} from "recharts";
import { claimsPremium } from "../api/Look";

const Linechart = ({ sel_year }) => {
  let Health = 0,
    Property = 0,
    Motor = 0,
    Miscellaneous = 0;
  let h_rev = 0,
    p_rev = 0,
    m_rev = 0,
    mis_rev = 0;
  const [claimsPremiumdata, setclaimsPremiumData] = useState([]);
  //const sel_year=2022
  useEffect(() => {
    const fetchData = async () => {
      try {
        const result = await claimsPremium();
        setclaimsPremiumData(result.success);
      } catch (error) {
        console.error("Error fetching data:", error);
      }
    };

    fetchData();
  }, []);
  claimsPremiumdata
    .filter((item) => item["data_iter_2.date_year"] === parseInt(sel_year))
    .map((item, index) => {
      switch (item["data_iter_2.lob"]) {
        case "Health":
          Health = item["data_iter_2.total_Claim_amount"] / 10000000;
          h_rev = item["data_iter_2.total_revenue"] / 10000000;
          break;
        case "Property":
          Property = item["data_iter_2.total_Claim_amount"] / 10000000;
          p_rev = item["data_iter_2.total_revenue"] / 10000000;
          break;
        case "Motor":
          Motor = item["data_iter_2.total_Claim_amount"] / 10000000;
          m_rev = item["data_iter_2.total_revenue"] / 10000000;
          break;
        case "Miscellaneous":
          Miscellaneous = item["data_iter_2.total_Claim_amount"] / 10000000;
          mis_rev = item["data_iter_2.total_revenue"] / 10000000;
          break;
      }
    });

  const data = [
    { name: "Health", Claims: Health.toFixed(2), Premium: h_rev.toFixed(2) },
    {
      name: "Property",
      Claims: Property.toFixed(2),
      Premium: p_rev.toFixed(2),
    },
    { name: "Motor", Claims: Motor.toFixed(2), Premium: m_rev.toFixed(2) },
    {
      name: "Miscellaneous",
      Claims: Miscellaneous.toFixed(2),
      Premium: mis_rev.toFixed(2),
    },
  ];

  return (
    <div
      style={{
        paddingTop: "10px",
        border: "1px dotted white",
        padding: "2px",
        backgroundColor: "White",
        borderRadius: "10px",
        boxShadow: "5px 5px 10px 0px rgba(0, 0, 0, 0.2)",
      }}
    >
      <p
        style={{
          fontSize: "18px",
          margin: "10px 0px 10px 20px",
          fontWeight: "600",
          color: "black",
        }}
      >
        Claims vs Premium
      </p>
      <div style={{ width: "42rem", height: "225px", paddingTop: "10px" }}>
        <ResponsiveContainer>
          <LineChart data={data}>
            <XAxis
              dataKey="name"
              tickLine={false}
              axisLine={false}
              padding={{ left: 40, right: 150 }}
            />

            <YAxis
              domain={[0, 500]}
              ticks={[0, 100, 200, 300, 400, 500]}
              hide={false}
              tickLine={false}
              axisLine={false}
              // tickFormatter={(value) => `${value} Cr`}
            />
            <Tooltip formatter={(value, name) => [`${value} Cr`, name]} />
            <Legend />
            <Line type="monotone" dataKey="Claims" stroke="red" dot={false} />
            <Line
              type="monotone"
              dataKey="Premium"
              stroke="green"
              dot={false}
            />
          </LineChart>
        </ResponsiveContainer>
      </div>
    </div>
  );
};

export default Linechart;
