// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `/* .sales {
  display: flex;
} */


.select{
  margin-left: 250px;
  margin-top:50px;
  
} 

.map-year-select {
  
  position: absolute;
  right: 50%;
  top: 13%;
  width: 130px;
  padding: 8px 16px;
  font-size: 14px; 
  border: 1px solid lightgrey;
   border-radius: 4px;
  outline: none;
  cursor: pointer; 
   background-color: white;
  border-radius: 15px;
} 

/* Optional: Style the arrow icon */
 .map-year-select::after {
  content: "\\25BC";
  position: absolute;
  top: 50%;
  right: 10px;
  transform: translateY(-50%);
  pointer-events: none;
} 

/* Optional: Style the selected option */
 .map-year-select option:checked {
  background-color: #e0e0e0;
  color: #333;
} 
`, "",{"version":3,"sources":["webpack://./src/sales.css"],"names":[],"mappings":"AAAA;;GAEG;;;AAGH;EACE,kBAAkB;EAClB,eAAe;;AAEjB;;AAEA;;EAEE,kBAAkB;EAClB,UAAU;EACV,QAAQ;EACR,YAAY;EACZ,iBAAiB;EACjB,eAAe;EACf,2BAA2B;GAC1B,kBAAkB;EACnB,aAAa;EACb,eAAe;GACd,uBAAuB;EACxB,mBAAmB;AACrB;;AAEA,mCAAmC;CAClC;EACC,gBAAgB;EAChB,kBAAkB;EAClB,QAAQ;EACR,WAAW;EACX,2BAA2B;EAC3B,oBAAoB;AACtB;;AAEA,wCAAwC;CACvC;EACC,yBAAyB;EACzB,WAAW;AACb","sourcesContent":["/* .sales {\n  display: flex;\n} */\n\n\n.select{\n  margin-left: 250px;\n  margin-top:50px;\n  \n} \n\n.map-year-select {\n  \n  position: absolute;\n  right: 50%;\n  top: 13%;\n  width: 130px;\n  padding: 8px 16px;\n  font-size: 14px; \n  border: 1px solid lightgrey;\n   border-radius: 4px;\n  outline: none;\n  cursor: pointer; \n   background-color: white;\n  border-radius: 15px;\n} \n\n/* Optional: Style the arrow icon */\n .map-year-select::after {\n  content: \"\\25BC\";\n  position: absolute;\n  top: 50%;\n  right: 10px;\n  transform: translateY(-50%);\n  pointer-events: none;\n} \n\n/* Optional: Style the selected option */\n .map-year-select option:checked {\n  background-color: #e0e0e0;\n  color: #333;\n} \n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
