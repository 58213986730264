import { React, useState, useEffect } from "react";
import MoreVertIcon from "@mui/icons-material/MoreVert";
import AddBoxIcon from "@mui/icons-material/AddBox";
import IndeterminateCheckBoxIcon from "@mui/icons-material/IndeterminateCheckBox";
import SaveAltOutlinedIcon from "@mui/icons-material/SaveAltOutlined";
import "../App.css";
import "./style.css";
import "../sales.css";
import Cards from "./Cards";
import Leftcard from "./leftcard";
import LineChart from "./linechart";
import LineChart1 from "./linechart1";
import Piechart from "./piechart";
import Tooltip from "@mui/material/Tooltip";
import SalesTool from "./SalesTool";
import { Map } from "../api/Look";

import InputLabel from "@mui/material/InputLabel";
import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";
import Select, { SelectChangeEvent } from "@mui/material/Select";

function Sales({ text, label }) {
  let north_rev = 0,
    south_rev = 0,
    east_rev = 0,
    west_rev = 0;
  const [sel_year, setYear] = useState("2023");
  const [mapdata, setMapData] = useState([]);
  const selectYearFunc = (event) => {
    setYear(event.target.value);
  };
  useEffect(() => {
    const fetchData = async () => {
      try {
        const result = await Map();
        setMapData(result.success);
      } catch (error) {
        console.error("Error fetching data:", error);
      }
    };

    fetchData();
  }, []);

  const [zoomLevel, setZoomLevel] = useState(1);

  const handleZoomIn = () => {
    setZoomLevel((prevZoomLevel) => Math.min(3, prevZoomLevel + 0.1));
  };

  const handleZoomOut = () => {
    setZoomLevel((prevZoomLevel) => Math.max(0.5, prevZoomLevel - 0.1));
  };

  const calculateZoomedPosition = (position) => {
    return position * zoomLevel;
  };

  const [isHovered1, setIsHovered1] = useState("");

  const handleMouseEnter1 = (e, index) => {
    setIsHovered1(index);
    // console.log(index);
  };

  const handleMouseLeave1 = () => {
    setIsHovered1(false);
  };
  mapdata
    .filter((item) => item["data_iter_2.date_year"] === parseInt(sel_year))
    .map((item, index) => {
      switch (item["data_iter_2.zone"]) {
        case "West":
          west_rev += parseFloat(
            (item["data_iter_2.total_revenue"] / 10000000).toFixed(2)
          );
          break;
        case "East":
          east_rev += parseFloat(
            (item["data_iter_2.total_revenue"] / 10000000).toFixed(2)
          );
          break;
        case "North":
          north_rev += parseFloat(
            (item["data_iter_2.total_revenue"] / 10000000).toFixed(2)
          );
          break;
        case "South":
          south_rev += parseFloat(
            (item["data_iter_2.total_revenue"] / 10000000).toFixed(2)
          );
          break;
      }
    });
  const pointers = [
    {
      id: 1,
      left: 220,
      top: 250,
      color: "#FF0000",
      content: { title: "Northern Zone", value: north_rev.toFixed(2) + " Cr" },
    },
    {
      id: 2,
      left: 110,
      top: 460,
      color: "#FFA500",
      content: { title: "Western Zone", value: west_rev.toFixed(2) + " Cr" },
    },
    {
      id: 3,
      left: 430,
      top: 450,
      color: "#000000",
      content: { title: "Eastern Zone", value: east_rev.toFixed(2) + " Cr" },
    },
    {
      id: 4,
      left: 235,
      top: 665,
      color: "#008000",
      content: { title: "Southern Zone", value: south_rev.toFixed(2) + " Cr" },
    },
  ];
  return (
    <div style={{ width: "100%" }}>
      <div className="sales" style={{ padding: "10px 259px 0px 50px" }}>
        <div style={{ display: "flex", height: "870px" }}>
          <div style={{ flex: 1 }}>
            <Leftcard sel_year={sel_year} />
          </div>

          <div
            style={{
              flex: 2,
              marginLeft: "-30px",
              position: "relative",
              overflow: "hidden",
              // top:"10px"
            }}
          >
            {/* MAP */}
            <div
              style={{
                transform: `scale(${zoomLevel})`,
                transition: "transform 0.3s ease-in-out",
                // height: "100%",
                // zIndex: 1,
              }}
            >
              <select
                name="year"
                id="years"
                onChange={selectYearFunc}
                className="map-year-select"
              >
                <option value="2023">2023</option>
                <option value="2022">2022</option>
                <option value="2021">2021</option>
              </select>
              {/* <div className="map-year-select">
          <FormControl sx={{ m: 0, minWidth: 100 }} size="small">
              <InputLabel id="demo-select-small-label">Year</InputLabel>
              <Select
                name="year"
                labelId="demo-select-small-label"
                id="years"
                label="Year"
                value={sel_year}
                onChange={selectYearFunc}
              >
                <MenuItem value={2023}>2023</MenuItem>
                <MenuItem value={2022}>2022</MenuItem>
                <MenuItem value={2021}>2021</MenuItem>
              </Select>
            </FormControl>
            </div> */}
              {/* <select
  name="year"
  id="years"
  onChange={selectYearFunc}
  className="map-year-select"
  style={{ padding: '8px', minWidth: '80px', fontSize: '18px', borderRadius: '4px' }}
>
  <option value="2023">2023</option>
  <option value="2022">2022</option>
  <option value="2021">2021</option>
</select> */}

              <img
                src={"/Map.png"}
                style={{
                  width: "700px",
                  height: "820px",
                  borderRadius: "8px",
                  aspectRatio: "auto",
                  cursor: "grab",
                  marginTop: "5%",
                  // zIndex: 1,
                }}
                alt="map"
              />
            </div>

            {pointers.map((pointer, index) => (
              <div
                key={pointer.id}
                style={{
                  position: "absolute",
                  left: calculateZoomedPosition(pointer.left),
                  top: calculateZoomedPosition(pointer.top),
                  transform: "translate(-50%, -50%)",
                  width: "10px",
                  height: "10px",
                  backgroundColor: "red", // Customize the color as needed
                  borderRadius: "50%",
                  // cursor: "pointer",
                }}
                onMouseEnter={(e) => handleMouseEnter1(e, index)}
                onMouseLeave={handleMouseLeave1}
              >
                <Tooltip
                  open={isHovered1 === index}
                  onClose={handleMouseLeave1}
                  title={<SalesTool index={index} />}
                >
                  {/* <SalesTool /> */}
                </Tooltip>
                <div
                  style={{
                    backgroundColor: "#ffffff",
                    borderRadius: "8px",
                    padding: "10px",
                    fontSize: "12px",
                    width: "140px",
                    height: "35px",
                    position: "absolute",
                    bottom: "30px",
                    left: "50%",
                    transform: "translateX(-50%)",
                    boxShadow: "0 2px 8px rgba(0, 0, 0, 0.1)",
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "center",
                  }}
                >
                  <span
                    style={{
                      width: "0.5px",
                      height: "25px",
                      backgroundColor: pointer.color,
                      borderRadius: "2px",
                      display: "flex",
                      color: "white",
                      paddingRight: "4px",
                    }}
                  ></span>
                  <span style={{ padding: "8px" }}>
                    <p>{pointer.content.title}</p>
                    <h2>{pointer.content.value}</h2>
                  </span>
                </div>
                <div
                  style={{
                    position: "absolute",
                    bottom: "20px",
                    left: "50%",
                    width: 0,
                    height: 0,
                    borderLeft: "10px solid transparent",
                    borderRight: "10px solid transparent",
                    borderTop: "10px solid #ffffff",
                    transform: "translateX(-50%)",
                  }}
                ></div>
              </div>
            ))}
          </div>
          {/* <div
            style={{
              position: "absolute",
              top: "20px",
              left: "20px",
              // backgroundColor:'purple',
              padding: "40px",
              left: "60%",
              top: "20%",
              display: "flex",
              flexDirection: "column",
              width: "20px",
            }}
          >
            <button onClick={handleZoomIn} style={{ marginBottom: "3px" }}>
              +
            </button>
            <button onClick={handleZoomOut}>-</button>
          </div> */}
        </div>

        {/* CHARTS CONTAINER */}
        <div className="charts-container">
          <Cards sel_year={sel_year} />
          {/* <div>
          <h4 className="pieChart-heading">Inventory metrics</h4>
          <Piechart sel_year={sel_year} />
        </div>

        <LineChart sel_year={sel_year} /> */}
        </div>

        {/* LINE CHART */}
        <div
          style={{
            flex: 1,
            // width: "30%",
            width: "30%",
            position: "absolute",
            top: "8.5%",
            right: "5rem",
            // alignItems: "left",
          }}
        >
          <div style={{ padding: "2px" }}>
            <h4
              className="pieChart-heading"
              style={{ marginBottom: "20px" }}
            ></h4>
            <LineChart1 sel_year={sel_year} />
          </div>

          <div style={{ padding: "2px" }}>
            <h4 className="pieChart-heading">Claim Analysis</h4>
            <Piechart sel_year={sel_year} />
          </div>

          <LineChart sel_year={sel_year} />
        </div>
      </div>

      {/* <LineChart sel_year={sel_year} /> */}
      <div className="copy">
        <p>Copyright @ 2023 HDFC Ergo. All rights reserved.</p>
      </div>
    </div>
  );
}

export default Sales;
