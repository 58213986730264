import React from "react";
import {
  PieChart,
  Pie,
  Sector,
  Cell,
  ResponsiveContainer,
  Label,
} from "recharts";

const Doughnut = ({ hdfcErgo, bajajAllianz, iciciLombard }) => {
  console.log("hdfcergo: ", hdfcErgo);
  const hdfcErgoValue = parseFloat(hdfcErgo);
  const data = [
    { name: "Mahindra", value: hdfcErgoValue },
    { name: "Maruthi", value: parseFloat(bajajAllianz) },
    { name: "Tata", value: parseFloat(iciciLombard) },
  ];
  const COLORS = ["#D00404", "#004da8", "#EF8435"];
  return (
    <PieChart width={1100} height={1300}>
      <Pie
        data={data}
        cx={220}
        cy={220}
        innerRadius={170}
        outerRadius={180}
        fill="#8884d8"
        paddingAngle={10}
        dataKey="value"
      >
        {data.map((entry, index) => (
          <Cell key={`cell-${index}`} fill={COLORS[index % COLORS.length]} />
        ))}
      </Pie>
    </PieChart>
  );
};

export default Doughnut;
