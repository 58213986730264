import * as React from "react";
import { useEffect, useState } from "react";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";
import Tooltip from "@mui/material/Tooltip";
import { MapwithDirect } from "../api/Look";
// import { drillDown } from "../api/Look.js";
import "./style.css";
let 
  zonerev2023={
    "north":209124300,
    "east":266283900,
    "west":337861800,
    "south":263549700
  }
  let zonerev2022={
    "north":148885000,
    "east":197207400,
    "west":246847350,
    "south":194207400
  }
  let zonerev2021={
    "north":100629000,
    "east":139567400,
    "west":169163150,
    "south":133925850
  }
const SalesTool = ({ index }) => {
  const [mapdirectdata,setMapDirectData]=useState([])
  // console.log("salesTool: ", index);
  let zoneName, revenue, product, quantity;
  useEffect(() => {
    const fetchData = async () => {
      try {
        const result = await MapwithDirect();
        setMapDirectData(result.success);
      } catch (error) {
        console.error("Error fetching data:", error);
      }
    };

    fetchData();
  }, []);
  
  

// mapdirectdata
//   .filter((item) => item["data_iter_2.date_year"] === 2022)
//   .forEach((item) => {
//     switch (item["data_iter_2.zone"]) {
//       case "West":
//         west_rev += parseFloat((item["data_iter_2.total_revenue"] / 10000000).toFixed(2));
//         break;
//       case "East":
//         east_rev += parseFloat((item["data_iter_2.total_revenue"] / 10000000).toFixed(2));
//         break;
//       case "North":
//         north_rev += parseFloat((item["data_iter_2.total_revenue"] / 10000000).toFixed(2));
//         break;
//       case "South":
//         south_rev += parseFloat((item["data_iter_2.total_revenue"] / 10000000).toFixed(2));
//         break;
//     }
//   });
  switch (index) {
    case 0:
      zoneName = "Northern Zone";
      revenue = "634547.20";
      product = "Direct Channel";
      quantity = zonerev2023["north"];
      break;
    case 1:
      zoneName = "West Zone";
      revenue = "1054644.00";
      product = "Direct Channel";
      quantity = zonerev2023["west"];
      break;
    case 2:
      zoneName = "East Zone";
      revenue = "850332.62";
      product = "Direct Channel";
      quantity = zonerev2023["east"];
      break;
    case 3:
      zoneName = "South Zone";
      revenue = "844439.44";
      product = "Direct Channel";
      quantity = zonerev2023["south"];
      break;
    default:
      zoneName = "Unknown Zone";
      revenue = "N/A";
      product = "N/A";
      quantity = "N/A";
  }

  

  return (
    
    // <Tooltip title="Show">
    <div
      style={{
        backgroundColor: "#ffffff",
        borderRadius: "8px",
        padding: "10px",
        fontSize: "12px",
        width: "120px",
        height: "125px",
        position: "absolute",
        bottom: "30px",
        left: "80%",
        transform: "translateX(-20%)",
        boxShadow: "0 2px 8px rgba(0, 0, 0, 0.1)",
        display: "flex",
        //   alignItems: "center",
        //   justifyContent: "center",
        //   flexDirection: 'column'
      }}
    >
      <span
        style={{
          width: "0.5px",
          height: "25px",
          backgroundColor: "blue",
          borderRadius: "2px",
          display: "flex",
          color: "white",
          paddingRight: "4px",
          // top: '80px'
        }}
      ></span>
          
      <span
        style={{
          display: "flex",
          flexDirection: "column",
          color: "black",
          paddingTop: "2px",
          padding: "5px",
          marginTop: "2px",
          fontSize:"18px"
        }}
      >
        
        <p>{zoneName}</p>
        {/* <h2>{revenue}</h2> */}
         <div className="h-line"></div>
        <p>{product}</p>
        <b>{(quantity/10000000).toFixed(2)+" Cr"}</b>
      </span>
    </div>
  );
};

export default SalesTool;
