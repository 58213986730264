import axios from "axios";

const url = "http://34.49.114.159";
export const getData = async () => {
  const selectedYear = localStorage.getItem("selectedyear");
  console.log("selectedYear", selectedYear);
  let changeyear = +selectedYear;
  console.log("test", changeyear, typeof changeyear);
  try {
    console.log("called get-data");
    let response = await axios.get(`${url}/get-data`);

    console.log("Result from getData:", response.data);
    return response.data;
  } catch (err) {
    console.log("Error fetching data:", err.message);
  }
};

export const centerVisualization = async () => {
  try {
    console.log("called center Visualization");
    let response = await axios.get(`${url}/get-data-center-visualization`);

    return response.data;
  } catch (err) {
    console.log("Error fetching data:", err.message);
  }
};
export const salesTrendZoneWise = async () => {
  try {
    console.log("called sales trend zone wise");
    let response = await axios.get(`${url}/get-data-sales-trend-zone-wise`);

    return response.data;
  } catch (err) {
    console.log("Error fetching data:", err.message);
  }
};
export const salesTrend = async () => {
  try {
    console.log("called sales trend");
    let response = await axios.get(`${url}/get-data-sales-trend`);

    return response.data;
  } catch (err) {
    console.log("Error fetching data:", err.message);
  }
};
export const totalSales = async () => {
  try {
    console.log("called total Sales");
    let response = await axios.get(`${url}/get-data-total-sales`);

    return response.data;
  } catch (err) {
    console.log("Error fetching data:", err.message);
  }
};

export const funnelData = async () => {
  try {
    console.log("called Funnel Data");
    let response = await axios.get(`${url}/get-data-funnel`);

    return response.data;
  } catch (err) {
    console.log("Error fetching data:", err.message);
  }
};

export const funnelEntityData = async () => {
  try {
    console.log("called Funnel Entity Data");
    let response = await axios.get(`${url}/get-data-funnel-entity`);

    return response.data;
  } catch (err) {
    console.log("Error fetching data:", err.message);
  }
};

export const entityPercentDifference = async () => {
  try {
    console.log("called Entity Percent Data");
    let response = await axios.get(`${url}/get-data-entity-percent-difference`);

    return response.data;
  } catch (err) {
    console.log("Error fetching data:", err.message);
  }
};
export const drillDown = async () => {
  try {
    console.log("called drill Down Data");
    let response = await axios.get(`${url}/get-data-drill-down`);

    return response.data;
  } catch (err) {
    console.log("Error fetching data:", err.message);
  }
};
export const policyPremium = async () => {
  try {
    console.log("called Policy Premium Data");
    let response = await axios.get(`${url}/get-data-policy-premium`);

    return response.data;
  } catch (err) {
    console.log("Error fetching data:", err.message);
  }
};
export const hdfcMetrics = async () => {
  try {
    console.log("called hdfc Metrics Data");
    let response = await axios.get(`${url}/get-data-hdfc-metrics`);

    return response.data;
  } catch (err) {
    console.log("Error fetching data:", err.message);
  }
};
export const hdfcPremiumTrend = async () => {
  try {
    console.log("called hdfc Premium Trend Data");
    let response = await axios.get(`${url}/get-data-hdfc-premium-trend`);

    return response.data;
  } catch (err) {
    console.log("Error fetching data:", err.message);
  }
};
export const claimsPremium= async () => {
  try {
    console.log("called claimsPremium Data");
    let response = await axios.get(`${url}/get-data-claims-premium`);

    return response.data;
  } catch (err) {
    console.log("Error fetching data:", err.message);
  }
};
export const agentsAndBrokers = async () => {
  try {
    console.log("called agents And Brokers Data");
    let response = await axios.get(`${url}/get-data-agents-brokers`);

    return response.data;
  } catch (err) {
    console.log("Error fetching data:", err.message);
  }
};
export const claimAnalysis = async () => {
  try {
    console.log("called claim Analysis Data");
    let response = await axios.get(`${url}/get-data-claim-analysis`);

    return response.data;
  } catch (err) {
    console.log("Error fetching data:", err.message);
  }
};

export const Map = async () => {
  try {
    console.log("called  map Data");
    let response = await axios.get(`${url}/get-data-map`);

    return response.data;
  } catch (err) {
    console.log("Error fetching data:", err.message);
  }
};
export const MapwithDirect = async () => {
  try {
    console.log("called  map Data");
    let response = await axios.get(`${url}/get-data-map-direct`);

    return response.data;
  } catch (err) {
    console.log("Error fetching data:", err.message);
  }
};