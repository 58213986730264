import React, { useState, useEffect } from "react";
import {
  LineChart,
  Line,
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip,
  Legend,
  ResponsiveContainer,
} from "recharts";
import { hdfcPremiumTrend } from "../api/Look";
const Linechart1 = () => {
  const [hdfcPremiumTrenddata, setHdfcPremiumTrendData] = useState([]);
  let health2021,
    health2022,
    health2023,
    motor2021,
    motor2022,
    motor2023,
    property2021,
    property2022,
    property2023,
    misc2021,
    misc2022,
    misc2023;
  useEffect(() => {
    const fetchData = async () => {
      try {
        const result = await hdfcPremiumTrend();
        setHdfcPremiumTrendData(result.success);
      } catch (error) {
        console.error("Error fetching data:", error);
      }
    };
    fetchData();
  }, []);
  hdfcPremiumTrenddata
    .filter((item) => item["data_iter_2.date_year"] === 2021)
    .map((item, index) => {
      switch (item["data_iter_2.lob"]) {
        case "Health":
          health2021 = (item["data_iter_2.total_revenue"] / 10000000).toFixed(2);
          break;
        case "Miscellaneous":
          misc2021 = (item["data_iter_2.total_revenue"] / 10000000).toFixed(2);
          break;
        case "Motor":
          motor2021 = (item["data_iter_2.total_revenue"] / 10000000).toFixed(2);
          break;
        case "Property":
          property2021 = (item["data_iter_2.total_revenue"] / 10000000).toFixed(2);
          break;
      }
    });
  hdfcPremiumTrenddata
    .filter((item) => item["data_iter_2.date_year"] === 2022)
    .map((item, index) => {
      switch (item["data_iter_2.lob"]) {
        case "Health":
          health2022 = (item["data_iter_2.total_revenue"] / 10000000).toFixed(2);
          break;
        case "Miscellaneous":
          misc2022 = (item["data_iter_2.total_revenue"] / 10000000).toFixed(2);
          break;
        case "Motor":
          motor2022 = (item["data_iter_2.total_revenue"] / 10000000).toFixed(2);
          break;
        case "Property":
          property2022 = (item["data_iter_2.total_revenue"] / 10000000).toFixed(2);
          break;
      }
    });
  hdfcPremiumTrenddata
    .filter((item) => item["data_iter_2.date_year"] === 2023)
    .map((item, index) => {
      switch (item["data_iter_2.lob"]) {
        case "Health":
          health2023 = (item["data_iter_2.total_revenue"] / 10000000).toFixed(2);
          break;
        case "Miscellaneous":
          misc2023 = (item["data_iter_2.total_revenue"] / 10000000).toFixed(2);
          break;
        case "Motor":
          motor2023 = (item["data_iter_2.total_revenue"] / 10000000).toFixed(2);
          break;
        case "Property":
          property2023 = (item["data_iter_2.total_revenue"] / 10000000).toFixed(2);
          break;
      }
    });
  const data = [
    {
      name: "2021",
      Health: health2021,
      Property: property2021,
      Motor: motor2021,
      Miscellaneous: misc2021,
    },
    {
      name: "2022",
      Health: health2022,
      Property: property2022,
      Motor: motor2022,
      Miscellaneous: misc2022,
    },
    {
      name: "2023",
      Health: health2023,
      Property: property2023,
      Motor: motor2023,
      Miscellaneous: misc2023,
    },
  ];

  return (
    <div style={{ position: "relative", left: "10%", marginBottom: "10px" }}>
      <div
        style={{
          width: "80%",
          border: "1px solid lightgrey",
          height: 200,
          backgroundColor: "White",
          padding: "10px 10px 0px",
          borderRadius: "10px",
          boxShadow: "5px 5px 10px 0px rgba(0, 0, 0, 0.2)",
        }}
      >
        <div
          style={{
            fontSize: "18px",
            color: "black",
            fontWeight: "600",
            marginLeft: "20px",
          }}
        >
          Premium Trend
        </div>
        <ResponsiveContainer>
          <LineChart data={data} style={{ height: "90%" }}>
            <XAxis
              dataKey="name"
              tickLine={false}
              axisLine={false}
              interval={0}
            />
            <YAxis
              domain={[0, 200]}
              axisLine={false}
              ticks={[0, 40, 80, 120, 160, 200]}
              hide={true}
              tickLine={false}
            />
            <Tooltip formatter={(value, name) => [`${value} Cr`, name]} />
            {/* <Legend /> */}
            <Line
              type="monotone"
              dataKey="Health"
              stroke="#8884d8"
              dot={false}
            />
            <Line
              type="monotone"
              dataKey="Motor"
              stroke="#82ca9d"
              dot={false}
            />
            <Line
              type="monotone"
              dataKey="Property"
              stroke="#FF5733"
              dot={false}
            />
            <Line
              type="monotone"
              dataKey="Miscellaneous"
              stroke="#FFD700"
              dot={false}
            />
          </LineChart>
        </ResponsiveContainer>
      </div>
    </div>
  );
};

export default Linechart1;
